<template>
    <div>
        <SkeletonLoaderCard height="400" v-if="loading && history.length === 0"/>
        <v-card class="custom-border border" v-else>
            <v-card-title class="d-flex flex-wrap align-center justify-space-between">
                <section class="d-flex flex-row align-center">
                    <v-icon color="primary" class="pr-2">mdi-clock-outline</v-icon>
                    <div class="f18 primary-font secondary-1--text fw600">Activity Log</div>
                </section>
                <section class="d-flex flex-row align-center justify-end ml-auto">
                    <span class="poppins fw400 py-0 secondary-2--text f14 mr-2">
                        DATE: 
                    </span>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        style="max-width: 600px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                class="f14 ma-0 pa-0"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="() => {
                                    $refs.menu.save(date)
                                    menu = false
                                }"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </section>
            </v-card-title>
            <v-progress-linear
                indeterminate
                color="primary"
                rounded
                height="3px"
                v-if="loading"
            ></v-progress-linear>
            <v-divider></v-divider>
        <v-sheet height="auto" max-height="50vh" class="d-flex overflow-y-auto scroller flex-wrap ma-5 fade">
                <v-timeline
                    align-top
                    dense
                    v-if="history.length > 0 && !loading"
                >
                    <v-timeline-item
                        v-for="(item, i) in history"
                        :key="i"
                        :color="item.hasOwnProperty('revoked') ? (item.revoked ? 'secondary' : 'primary') : 'success'"
                        small
                        fill-dot
                        >
                        <div v-if="item.hasOwnProperty('revoked')">
                            <v-btn
                                    elevation="0"
                                    x-small
                                    flat
                                    :color="item.revoked ? 'secondary' : 'primary'"
                                    class="mb-1 text-uppercase"
                                    dense
                                >
                                {{ item.revoked ? 'Log out' : 'Log in' }}
                            </v-btn>
                        </div>
                        <div v-else>
                            <section v-if="item.log_name === 'user_enrollment'">
                                <v-btn
                                    elevation="0"
                                    x-small
                                    flat
                                    color="success"
                                    class="mb-1"
                                    dense
                                >
                                    ENROLLED
                                </v-btn>
                                <div class="fw500">
                                    {{ item?.course?.title }}
                                </div>
                                <div class="fw500 f11 text-uppercase secondary-1--text mt-2" v-if="item?.subject_id !== item?.causer_id">
                                    ENROLLED BY: 
                                    {{ item?.causer?.first_name ? item?.causer?.first_name : item?.causer?.role }}
                                    {{ $userFormat.middle_initial(item?.causer?.middle_name) }}
                                    {{ item?.causer?.last_name ? item?.causer?.last_name : 'Account' }}
                                    {{ item?.causer?.suffix ? item?.causer?.suffix : '' }}
                                </div>
                            </section>
                            <section v-if="item.log_name === 'user_course_activity'">
                                <v-btn
                                    elevation="0"
                                    x-small
                                    flat
                                    color="amber"
                                    class="mb-1 text-uppercase"
                                    dense
                                >
                                    {{ item?.description }}
                                </v-btn>
                                <div class="fw500">
                                    {{ item?.course?.title }}
                                </div>
                            </section>
                        </div>
                        <div class="secondary-2--text text-uppercase fw500 f10" >{{ $dateFormat.dayDateTime(item.created_at) }} </div>
                    </v-timeline-item>
                </v-timeline>

                <div class="fw400 py-0 secondary-2--text f14 mb-3" v-else-if="history.length === 0 && !loading">
                    You have no login activities for {{ $dateFormat.mmDDyy(this.date) }}
                </div>
            </v-sheet>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import SkeletonLoaderCard from '@/components/reusable/SkeletonLoaderCard.vue'

export default {
    components: {
        SkeletonLoaderCard
    },
    data: () => ({
        history: [],
        menu: null,
        loading: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),
    methods: {
        ...mapActions(['getLoginHistory', 'getActivityLog']),
    },
    mounted(){
        this.loading = true
        this.getActivityLog({ date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)}).then(res => {
            this.history = res
        }).finally(() => {
            this.loading = false
        })
        /* this.getLoginHistory({ date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)}).then(res => {
            this.history = res
        }) */
    },
    watch: {
        menu(){
            if(!this.menu){
                this.loading = true
                this.getActivityLog({ date_from: this.date, date_to: this.date}).then(res => {
                    this.history = res
                }).finally(() => {
                    this.loading = false
                })
                /* this.getLoginHistory({ date_from: this.date, date_to: this.date}).then(res => {
                    this.history = res
                }) */
            }
        }
    }
}
</script>